@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&family=Oswald:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Nunito+Sans&family=Oswald:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Hind:wght@500&family=Nunito+Sans&family=Oswald:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Archivo+Narrow:wght@500&family=Hind:wght@500&family=Nunito+Sans&family=Oswald:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Manrope:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Playfair+Display:wght@600&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Hind", sans-serif;
}

/* Home Page Marquee Style */
.marqueeText {
  font-family: "Oswald", sans-serif;
  background-color: black;
  color: white;
  padding: 10px;
}

.marqueeText div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 50px;
}

p {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Navigation Bar=====> */
.HeaderLogo {
  font-family: "Abril Fatface", cursive;
  font-weight: 400;
  font-size: 40px;
}

li {
  list-style: none;
}

li:hover {
  text-decoration: underline;
}

/* Swiper Slide  Css Mandatory Import= */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide {
  margin: 0px !important;
}

.SwiperDiv {
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(8, 8, 8, 0.73)),
    url("./app/assets/Images/banner.png");

  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  object-fit: cover;
  background-size: cover;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1111111;
  color: white;
}

/* Swiper Baner Css */
.SwipperButtonCont {
  display: flex;
  gap: 20px;
  margin: 20px;
}

/*Marqueeees css ===============================================>  */

.Marqueetext2 {
  background-color: white !important;
  align-items: center;
}

.blackodd {
  color: rgb(0, 0, 0) !important;
  -webkit-text-stroke: 1px black !important;
  -webkit-text-fill-color: white !important;
}

.whiteodd {
  color: rgb(255, 255, 255) !important;
  -webkit-text-stroke: 1px rgb(0, 0, 0) !important;
  -webkit-text-fill-color: rgb(0, 0, 0) !important;
}

.backgroundWithImage {
  width: "100%";
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.52),
      rgba(8, 8, 8, 0.73)),
    url("https://cdn.shopify.com/s/files/1/0624/9329/4741/files/slide-03_1920x900_crop_center.jpg?v=1662577906");
}

/* New Arrival */
.CustomiseProductImage {
  width: "100%";
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.6),
      rgba(8, 8, 8, 0.6)),
    url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoueqc-I3QtL6c5G4urQk71lL7EnUeKV6WfA&usqp=CAU");
}

.ButtonStyle {
  background: "black !important";
  color: "white !important";
}

.ButtonStyle:hover {
  background: "white";
  color: "black";
  border: "none";
}